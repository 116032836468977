import React from 'react';
import banklogo from "../../src/assets/images/bank-logo.png";


const App = () => {
   
  const [isHovering, setIsHovering] = React.useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
   <div>
   <div className='container-blog1'>
      <div>
        <div
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
        <img src={banklogo} alt="image" />

          Banking and Finance
        </div>

        {isHovering && (
          <div>
            
            <p><b>Banking and Finance</b>industry has been one of the earliest adopters of RPA. With a vast amount of data and repetitive tasks involved in banking operations, RPA has significantly reduced the workload on employees and streamlined processes. RPA has been used to automate tasks such as data entry, account reconciliation, customer onboarding, fraud detection, and loan processing. By automating these tasks, banks and financial institutions have been able to reduce costs, improve accuracy, and provide a better customer experience.</p>
  
          </div>
        )}
      </div>
      <div>
        <div
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
        <img src={banklogo} alt="image" />

            Insurance
        </div>

        {isHovering && (
          <div>
            
            <p>The <b>Insurance </b>industry is adopting RPA to automate their claims processing and underwriting processes. RPA can help insurers to process claims faster, reduce errors, and improve customer service. By automating the underwriting process, insurers can quickly and accurately assess risk and make informed decisions. This can lead to better pricing and coverage for policyholders.</p>    
  
          </div>
        )}
      </div>
    </div>
    <div className='container-blog1'>
      <div>
        <div
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
        <img src={banklogo} alt="image" />

          Healthcare
        </div>

        {isHovering && (
          <div>
            
            <p>The <b>Healthcare</b> industry has also benefited greatly from RPA implementation. RPA has been used to automate tasks such as patient scheduling, insurance claim processing, medical billing, and patient record management. By automating these tasks, healthcare providers have been able to reduce administrative tasks, improve patient care, and increase efficiency.</p>    
  
          </div>
        )}
      </div>
      <div>
        <div
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
        <img src={banklogo} alt="image" />

        Retail
        </div>

        {isHovering && (
          <div>
            
            <p>The <b>Retail</b> industry has been able to leverage RPA to improve its supply chain management, inventory management, and customer service. RPA has been used to automate tasks such as purchase order processing, shipment tracking, and customer service inquiries. By automating these tasks, retailers have been able to reduce costs, improve inventory management, and provide a better customer experience.</p>    
  
          </div>
        )}
      </div>
    </div>
    <div className='container-blog1'>
      <div>
        <div
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
        <img src={banklogo} alt="image" />

        Manufacturing
        </div>

        {isHovering && (
          <div>
            
            <p>The <b>Manufacturing</b> industry is one of the leading adopters of RPA technology. Manufacturers are using RPA to automate their production lines, inventory management, and supply chain processes. RPA robots can monitor and manage inventory levels, alerting workers when it's time to order new materials or restock. They can also streamline production by automating repetitive tasks like quality control checks and assembly line processes. Manufacturers are using RPA to achieve greater efficiency and productivity while reducing errors and costs.</p>    
  
          </div>
        )}
      </div>
      <div>
        <div
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
        <img src={banklogo} alt="image" />

        Logistics and Transportation
        </div>

        {isHovering && (
          <div>
            
            <p>The <b>Logistics and Transportation </b>industry is also adopting RPA to improve their operations. RPA is used to automate repetitive tasks such as tracking shipments, scheduling deliveries, and updating inventory levels. By automating these tasks, logistics and transportation companies can reduce errors, improve accuracy, and speed up their operations. Additionally, RPA can be used to optimize delivery routes, reduce fuel consumption, and improve overall efficiency.</p>    
  
          </div>
        )}
      </div>
    </div>
    <div className='container-blog1'>
      <div>
        <div
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
        <img src={banklogo} alt="image" />

        Energy and Utilities
        </div>

        {isHovering && (
          <div>
            
            <p>The <b>Energy and Utilities </b>sector is also adopting RPA to optimize their operations. RPA robots can automate tasks such as monitoring power grids, tracking energy usage, and managing maintenance schedules. By automating these tasks, energy and utilities companies can reduce downtime, improve reliability, and increase efficiency.</p>    
  
          </div>
        )}
      </div>
      <div>
        <div
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
        <img src={banklogo} alt="image" />

        Human Resources
        </div>

        {isHovering && (
          <div>
            
            <p><b>Human Resources</b> is another industry that is benefiting from RPA implementation. RPA is being used to automate tasks such as resume screening, onboarding, and offboarding processes. By automating these tasks, HR professionals can spend more time on strategic initiatives such as employee engagement, retention, and training.</p>    
  
          </div>
        )}
      </div>
    </div>

    </div>
  );
};

export default App;
